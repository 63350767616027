import { isClient } from './isClient';
var PATH_PREFIX_BLACKLIST = ['/nl', '/de'];
// Currently we have different definitions of multi-language regions URLS.
// For some cases even if language is a default we need to include path prefix,
// for other cases we need to remove them.
// Locale provides currently always adds path prefix for multi-languages regions.
// This method removes redundant default languages URLs prefixing.
export var getUrlPrefix = function (pathPrefix, isCurrent) {
    return isCurrent
        ? '#'
        : PATH_PREFIX_BLACKLIST.includes(pathPrefix)
            ? '/'
            : "".concat(pathPrefix, "/");
};
export var getCurrentUrl = function () { return (isClient() ? window.location.href : ''); };
export var getLocationOrigin = function () {
    return isClient() ? window.location.origin : '';
};
export var getLocationPathName = function () {
    return isClient() ? window.location.pathname : '';
};
export var getUrlWithQueryParams = function (values, baseUrl) {
    var urlToParse = baseUrl || getCurrentUrl();
    if (!urlToParse) {
        return '';
    }
    var url = new URL(urlToParse);
    Object.keys(values).forEach(function (key) {
        if (values[key] !== '') {
            url.searchParams.set(key, values[key]);
        }
        else {
            url.searchParams.delete(key);
        }
    });
    return url.href;
};
export var getUrlWithoutQueryParams = function (keys, baseUrl) {
    var urlToParse = baseUrl || getCurrentUrl();
    if (!urlToParse) {
        return '';
    }
    var url = new URL(urlToParse);
    keys.forEach(function (key) {
        url.searchParams.delete(key);
    });
    return url.href;
};
export var getQueryParamsString = function (params) {
    return new URLSearchParams(params).toString();
};
export var updateUrl = function (url) {
    if (window && window.history) {
        window.history.replaceState(null, '', url);
    }
};
export var resolveCategoryUrl = function (urlPath, redirectUrl) {
    if (redirectUrl === void 0) { redirectUrl = ''; }
    return redirectUrl || urlPath;
};
export var getRedirect = function (redirectUrl) { return function () {
    window.location.href = redirectUrl;
}; };

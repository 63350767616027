var STORAGE_KEY = 'recentSearches';
var RECENT_SEARCHES_LIMIT = 20;
export var getRecentSearches = function () {
    try {
        var recentSearches = window.localStorage.getItem(STORAGE_KEY);
        return recentSearches ? JSON.parse(recentSearches) : [];
    }
    catch (_a) {
        return [];
    }
};
export var clearRecentSearches = function () {
    try {
        window.localStorage.removeItem(STORAGE_KEY);
    }
    catch (_a) {
        return;
    }
};
export var saveRecentSearches = function (list) {
    try {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(list));
    }
    catch (_a) {
        return;
    }
};
var findIndex = function (list, keyword) {
    return list.findIndex(function (item) { return keyword === item.keyword; });
};
export var saveRecentSearch = function (_a) {
    var keyword = _a.keyword, thumbnail = _a.thumbnail;
    var list = getRecentSearches();
    var existingIndex = findIndex(list, keyword);
    if (existingIndex === 0) {
        return;
    }
    if (existingIndex > -1) {
        // Move existing item to the beginning of an array
        list.unshift(list.splice(existingIndex, 1)[0]);
    }
    else {
        list.unshift({ keyword: keyword, thumbnail: thumbnail });
    }
    if (list.length > RECENT_SEARCHES_LIMIT) {
        list.pop();
    }
    saveRecentSearches(list);
};

var isWebpSupportedInstance = null;
var isWebpSupported = function () {
    var _a;
    if (isWebpSupportedInstance !== null) {
        return isWebpSupportedInstance;
    }
    var elem = document.createElement('canvas');
    if ((_a = elem === null || elem === void 0 ? void 0 : elem.getContext) === null || _a === void 0 ? void 0 : _a.call(elem, '2d')) {
        isWebpSupportedInstance = elem
            .toDataURL('image/webp')
            .startsWith('data:image/webp');
    }
    else {
        isWebpSupportedInstance = false;
    }
    return isWebpSupportedInstance;
};
export default isWebpSupported;
